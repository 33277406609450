// Translated
// Migrated
<template lang="pug">
button.btn.btn-rounded.d-flex.align-items-center(
  type="button"
  :class="active ? [(activeClass ?? 'active'), 'pl-px-50 pr-3'] : [inactiveClass, 'px-4']"
  @click="$emit('click')"
)
  fa.mr-2(
    v-if="icon"
    :icon="icon"
  )
  span
    slot
</template>

<script>
export default defineNuxtComponent({
  props: {
    active: {
      type: Boolean,
      default: false,
    },

    activeClass: {
      type: String,
      default: null
    },

    inactiveClass: {
      type: String,
      default: null
    },

    icon: {
      type: Object,
      default: null
    }
  },

  emits: ['click']
})
</script>
